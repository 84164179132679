angular.module("app")
    .constant("_vRoutes", {
        fields: {
            item: "/"
        },
        auth: {
            login: "/auth/login.p/"
        }
    }).
    constant("_vParamTransformers", (function () {
        function listParamTransformer($v) {
            //var f = $v.f;
            //
            //if (f) {
            //    var tags = f.tags;
            //
            //    if (tags) {
            //        if (_.isArray(tags)) {
            //            f.tags = _.map(f.tags, function (tag) {
            //                return parseInt(tag)
            //            });
            //        } else {
            //            f.tags = [parseInt(tags)]
            //        }
            //    }
            //}

            return $v;
        }

        return {
            "fields.item": listParamTransformer
        }
    })()).
    config(function (_vProvider, _vParamTransformers, _vRoutes) {
        //_vProvider.setForceRefreshOnExternalUrlChange(true);
        _vProvider.setRoutes(_vRoutes);
        _vProvider.setParamTransformers(_vParamTransformers);
    }).
    run(function (_v) {
        //inject _v to init $rootScope.$v
    })
    .factory("_router", function(_v) {
        return {
            change: {
                farm: function(farmId) {
                    _v.change({set: {farm: farmId}})
                },
                field: function(fieldId) {
                    _v.change({set: {field: fieldId}})
                }
            }
        }
    });